// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDwbFQUPimFlNaKTBKykpTNQWcXHGFD7yk',
    authDomain: 'steyn-city-web-qa.firebaseapp.com',
    databaseURL: 'https://steyn-city-web-qa.firebaseio.com',
    projectId: 'steyn-city-web-qa',
    storageBucket: 'steyn-city-web-qa.appspot.com',
    messagingSenderId: '440402678609',
    appId: '1:440402678609:web:1e338cf991dd4f3d11b970'
  },
  sendgrid: {
    apiKey: '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',
    from: 'steyncitymailer'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
