import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as svgPanZoom from 'svg-pan-zoom';

declare const window;

@Component({
    selector: 'bucket-map',
    templateUrl: './bucket-map.component.html',
    styleUrls: ['./bucket-map.component.scss']
})
export class BucketMapComponent implements OnInit, AfterViewInit {
    @Input() bucket: any;
    map: any[] = new Array(100);
    mapTitle = 'PROMENADE';
    titleColor: string;

    constructor() {
    }

    ngOnInit() {
        this.map[0] = true;
    }

    ngAfterViewInit(): void {
        window.onload = () => {
            this.initMap();
        };
    }

    initMap() {
        setTimeout(() => {
        const panZoom =
            svgPanZoom('#map', {
                zoomEnabled: true,
                controlIconsEnabled: true,
                center: true
            });

        panZoom.zoom(1);
        }, 150);
    }

    toggleMap(map: any[], number: number, title, color) {
        this.mapTitle = title;
        this.titleColor = color || '#fff';

        for (let i = 0; i <= 32; i++) {
            map[i] = false;
        }

        map[number] = !map[number];

        setTimeout(() => {
            this.initMap();
        }, 50);
    }
}
