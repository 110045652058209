import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NguCarouselModule } from '@ngu/carousel';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { LottieModule } from 'ngx-lottie';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { BookTourButtonComponent } from './home-page/book-tour/book-tour-button/book-tour-button.component';
import { BookTourComponent } from './home-page/book-tour/book-tour.component';
import { BucketBlackComponent } from './home-page/bucket-black/bucket-black.component';
import { BucketBlueComponent } from './home-page/bucket-blue/bucket-blue.component';
import { BucketMapComponent } from './home-page/bucket-map/bucket-map.component';
import { BucketRedExtendedComponent } from './home-page/bucket-red-extended/bucket-red-extended.component';
import { BucketRedComponent } from './home-page/bucket-red/bucket-red.component';
import { ContactAgentComponent } from './home-page/contact-agent/contact-agent.component';
import { HomePageComponent } from './home-page/home-page.component';
import { HexagonGridComponent } from './home-page/hundred-reasons-why/hexagon-grid/hexagon-grid.component';
import { HexagonIconComponent } from './home-page/hundred-reasons-why/hexagon-icon/hexagon-icon.component';
import { HexagonLabelComponent } from './home-page/hundred-reasons-why/hexagon-label/hexagon-label.component';
import { HexagonComponent } from './home-page/hundred-reasons-why/hexagon/hexagon.component';
import { DialogHexagonComponent } from './home-page/hundred-reasons-why/hundred-reasons-dialog/dialog-hexagon/dialog-hexagon.component';
import { HundredReasonsDialogComponent } from './home-page/hundred-reasons-why/hundred-reasons-dialog/hundred-reasons-dialog.component';
import { HundredReasonsWhyComponent } from './home-page/hundred-reasons-why/hundred-reasons-why.component';
import { PrivacyPolicyComponent } from './home-page/privacy-policy/privacy-policy.component';
import { PropertiesComponent } from './home-page/properties/properties.component';
import { SearchComponent } from './home-page/search/search.component';
import { ShareButtonComponent } from './home-page/share-button/share-button.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { SsrPageComponent } from './ssr-page/ssr-page.component';
import { SubscribeDialogComponent } from './subscribe-dialog/subscribe-dialog.component';

@NgModule({
    imports: [CommonModule, RouterModule, ReactiveFormsModule, NguCarouselModule, FormsModule, LottieModule, InViewportModule, SlickCarouselModule],
    declarations: [
        HomePageComponent,
        MainNavComponent,
        LoadingSpinnerComponent,
        NotificationMessageComponent,
        SsrPageComponent,
        BucketBlackComponent,
        BucketBlueComponent,
        BucketRedComponent,
        BucketRedExtendedComponent,
        BucketMapComponent,
        ShareButtonComponent,
        BookTourComponent,
        ContactAgentComponent,
        SearchComponent,
        SubscribeDialogComponent,
        HexagonComponent,
        HexagonGridComponent,
        HundredReasonsWhyComponent,
        HexagonIconComponent,
        HexagonLabelComponent,
        DialogHexagonComponent,
        HundredReasonsDialogComponent,
        BookTourButtonComponent,
        PropertiesComponent,
        PrivacyPolicyComponent,
    ],
    exports: [
        MainNavComponent,
        LoadingSpinnerComponent,
        NotificationMessageComponent,
        BucketBlackComponent,
        ContactAgentComponent,
        BucketBlueComponent,
        BucketRedComponent,
        ShareButtonComponent,
        BookTourComponent,
    ]
})
export class UiModule {
}
