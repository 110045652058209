import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dialog-hexagon',
  templateUrl: './dialog-hexagon.component.svg',
  styleUrls: ['./dialog-hexagon.component.scss']
})
export class DialogHexagonComponent implements OnInit {

  @Input() background = 'none'; 
  constructor() { }

  ngOnInit() {
  }

}
