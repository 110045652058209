import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from "lodash";
import {ElementScrollPercentage} from "../../element-scroll-percentage";
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";

declare const window;

@Component({
    selector: 'subscribe-dialog',
    templateUrl: './subscribe-dialog.component.html',
    styleUrls: ['./subscribe-dialog.component.scss']
})
export class SubscribeDialogComponent implements OnInit {
    pageData: any[];
    isSubscribeVisible = false;
    subscribe: any = {};
    formSubmitting = false;
    form: FormGroup;

    public innerScroll: number;
    public pageScroll: number;
    public demoRange: number[];


    constructor(private readonly afs: AngularFirestore, private readonly elementScrollPercentage: ElementScrollPercentage, private readonly route: ActivatedRoute, private readonly router: Router) {
        this.demoRange = _.range(15);
        this.innerScroll = 0;
        this.pageScroll = 0;

        let url = 'home';

        if (this.route.snapshot.url && this.route.snapshot.url.length > 0) {
            url = this.route.snapshot.url[0].path;
        }

        this.fetchContent(url);

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                const routeUrl = event.url.substring(1);
                this.fetchContent(routeUrl);
            }
        });
    }

    ngOnInit() {
        this.form = new FormGroup({
            'name': new FormControl(this.subscribe.name, [Validators.required]),
            'email': new FormControl(this.subscribe.email, [Validators.required, Validators.email]),
        });

        this.elementScrollPercentage
            .getScrollAsStream() // Defaults to Document if no Element supplied.
            .subscribe(
                (percent: number): void => {
                    this.pageScroll = percent;

                }
            );
    }

    toggleSubscribe (open: boolean = true) {
        const subscribeDialogContainerStyle = document.getElementById('subscribe-dialog-container').style;
        if (open) {
            subscribeDialogContainerStyle.transform = 'translateX(0)';
            subscribeDialogContainerStyle.opacity = '1';
        } else {
            subscribeDialogContainerStyle.transform = 'translateX(100vw)';
            subscribeDialogContainerStyle.opacity = '0';
        }
    }

    private fetchContent(doc) {
        this.afs.collection('pages', ref => ref.where('urlPath', '==', doc))
            .valueChanges()
            .subscribe(content => {
                this.pageData = content;
            });
    }

    sendMail() {
        this.form.markAsTouched();
        this.subscribe['type'] = 'subscribe';

        if (this.form.valid && !this.formSubmitting) {
            this.formSubmitting = true;
            this.afs.collection('contact')
                .add(this.subscribe)
                .then(() => {
                    this.toggleSubscribe(false);
                    this.form.reset();
                    this.formSubmitting = false;
                }).catch(() => {

            });
        }
    }
}
