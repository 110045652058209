import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'bucket-red-extended',
  templateUrl: './bucket-red-extended.component.html',
  styleUrls: ['./bucket-red-extended.component.scss']
})
export class BucketRedExtendedComponent implements OnInit, AfterViewInit {
  @Input() bucket: any;
  @Output() bucketChange = new EventEmitter<any>();
  @ViewChild("bucketElement", null) bucketProp: ElementRef;

  url = window.location.href;

  constructor() { }

  ngOnInit() {
    this.bucket.isVideo = this.bucket.isVideo === undefined ?
        this.bucket.primaryImageUrl.includes('videos') :
        this.bucket.isVideo;
  }

  hideBucket() {
    this.bucketChange.emit(null);
  }

  ngAfterViewInit(): void {
    this.bucketProp.nativeElement.scrollIntoView({ behavior: "smooth", block: "start" });
  }
}
