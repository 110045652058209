import playnodes from './steyncity_01_playnode.jpg';
import dinopark from './steyncity_08_dinopark.jpg';
import kidspool from './steyncity_13_kidspool.jpg';
import pedestrianboulevard from './steyncity_14_pedestrianboulevard.jpg';
import outdoorfitnesszone from './steyncity_20_outdoorfitnesszone.jpg';
import equestrianclub from './steyncity_23_equestrianclub.jpg';
import golfcourse from './steyncity_28_golfcourse.jpg';
import golfcartgps from './steyncity_39_golfcartgps.jpg';
import nineteenrestaurant from './steyncity_43_nineteenrestaurant.jpg';
import authorisedvisitorcontrol from './steyncity_60_authorisedvisitorcontrol.jpg';
import landart from './steyncity_61_landart.jpg';
import birdhides from './steyncity_62_birdhides.jpg';
import parklands from './steyncity_63_parklands.jpg';
import landscapedgreenbelts from './steyncity_64_landscapedgreenbelts.jpg';
import treesshrubs from './steyncity_72_treesshrubs.jpg';
import treelinedwalkways from './steyncity_74_treelinedwalkways.jpg';
import resortpool from './steyncity_79_resortpool.jpg';
import resortpool_ from './steyncity_84_resortpool.jpg';
import beachclub from './steyncity_85_beachclub.jpg';
import residentsapp from './steyncity_91_residentsapp.jpg';
import capitalpark from './steyncity_98_capitalpark.jpg';
import schoolcampus from './steyncity_99_schoolcampus.jpg';
import citycentre from './steyncity_100_citycentre.jpg';

export default {
    "01": playnodes,
    "08": dinopark,
    "13": kidspool,
    "14": pedestrianboulevard,
    "20": outdoorfitnesszone,
    "23": equestrianclub,
    "28": golfcourse,
    "39": golfcartgps,
    "43": nineteenrestaurant,
    "60": authorisedvisitorcontrol,
    "61": landart,
    "62": birdhides,
    "63": parklands,
    "64": landscapedgreenbelts,
    "72": treesshrubs,
    "74": treelinedwalkways,
    "79": resortpool,
    "84": resortpool_,
    "85": beachclub,
    "91": residentsapp,
    "98": capitalpark,
    "99": schoolcampus,
    "100": citycentre,
}