import { AfterViewInit, Component, OnDestroy, OnInit, AfterContentInit } from '@angular/core';
import { LottieOptions } from 'ngx-lottie';
import sliderImages from '../../../../assets/images/100-reasons-slider-images';
import {ElementScrollPercentage} from "../../../element-scroll-percentage";

declare var $: any;

@Component({
    selector: 'hundred-reasons-why',
    templateUrl: './hundred-reasons-why.component.html',
    styleUrls: ['./hundred-reasons-why.component.scss']
})

export class HundredReasonsWhyComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {

    public pageScroll: number;
    public innerScroll: number;
    bucket: any;
    primaryImageUrls = [
        sliderImages.image_01,
        // sliderImages.image_01,
        // sliderImages.image_01,
        // sliderImages.image_03,
        // sliderImages.image_05,
        // sliderImages.image_06,
    ];

    options: LottieOptions = {
        path: 'https://firebasestorage.googleapis.com/v0/b/steyn-city-web.appspot.com/o/lottie%2Fyourworld.json?alt=media&token=0045fec7-2fa4-4f2b-9fc6-7f5019a8e2c6',
        loop: false
    };
    slideConfig = {
        speed: 1500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        pauseOnHover: false,
    };
    isMobile = false;
    isDialogOpen = false;
    dialogData: any;
    fixedMenuButtomPosition = 'auto';
    
    constructor(private readonly elementScrollPercentage: ElementScrollPercentage,) {
        this.pageScroll = 0;
        this.innerScroll = 0;
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        window.addEventListener('scroll', this.scroll,{ passive: true })
        this.evaluateScroll();
        this.elementScrollPercentage
        .getScrollAsStream() // Defaults to Document if no Element supplied.
        .subscribe(
            (percent: number): void => {
                this.pageScroll = percent;
            }
        );
    }

    // change decal background color to the bucket background color
    ngAfterContentInit(): void {
        setTimeout(() => { 
            $('#bucket-decal-svg').attr('style', `fill: ${'#414141'} !important`);
        }, 500);
    }

    ngOnDestroy() {
        window.removeEventListener('scroll', this.scroll, true);
    }

    evaluateScroll() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            const htmlStyle = document.getElementsByTagName('html')[0].style;
            htmlStyle.scrollBehavior = 'unset';
            window.scrollTo(0, 0);

            if (window.location.hash !== '') {
                htmlStyle.scrollBehavior = 'smooth';
                window.scrollTo(0, document.getElementById((window.location.hash).split('#')[1]).offsetTop);
                htmlStyle.scrollBehavior = 'unset';
            }
        }, 300);
    }

    scrollDown() {
        window.scrollTo({
            top: window.innerHeight + window.pageYOffset
            , behavior: 'smooth'
        });
    }

    scrollToSection(elementId: string) {
      if(elementId) {  
        const el = document.getElementById(elementId);
        el.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
        });
     }
    }

    scroll = (event): void => {

        const downAction = document.getElementsByClassName('down-action')[0] as HTMLElement;
        const menu = document.getElementsByClassName('sticky-menu-container')[0];
        const footerHeight = document.getElementsByClassName('row no-gutters nice-work')[0].clientHeight + document.getElementsByClassName('footer')[0].clientHeight;

        const windowHeight = document.body.clientHeight;
        const scrollPosition = window.scrollY;

        if (menu) {
            if ((scrollPosition + footerHeight) >= (windowHeight - footerHeight - ((menu.clientHeight || 0)/1.2) )) {
                (menu as HTMLElement).style.opacity = '0';
                if (downAction) {
                    downAction.style.display = 'none';
                }
            } else {
                (menu as HTMLElement).removeAttribute('style');
                if (downAction) {
                    downAction.style.display = 'block';
                }
            }
        }
    };

    checkMobile() {
        this.isMobile = !!navigator.userAgent.match(/iphone|android|blackberry|mobile|phone|opera mini/ig) || false;
    }

    getWindowTopY() {
        return window.pageYOffset;
    }

    onSelectedHexagon($event) {
        if(window.pageYOffset === 0) {
            window.scrollBy(0, 1);
        }

       const html = document.getElementsByTagName('html')[0];
       html.style.overflow = 'hidden';
       this.dialogData = $event;
       this.isDialogOpen = true;
    }

    onCloseHexagonModal(){
        const html = document.getElementsByTagName('html')[0];
        html.style.overflow = 'auto';
        this.isDialogOpen = false;
        this.dialogData = null;
    }
}
