import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

type Validator<T extends FormControl> = (c: T) => { [error: string]: any };

function validateString(c: FormControl) {
    const REGEX = /.*\S.*/;
    return REGEX.test(c.value) ? null : {
        validateString: {
            valid: false
        }
    };
}

@Component({
    selector: 'book-tour',
    templateUrl: './book-tour.component.html',
    styleUrls: ['./book-tour.component.scss']
})
export class BookTourComponent implements OnInit {
    @Input() bucket: any;

    book: any = {};
    form: FormGroup;
    formSubmitting = false;
    slide = false;
    subscribeChecked = false;
    activeForm: string = null;
    disableSubscribe = true;
    @Input() btnBackgroundColor = '';
    @Input() btnBorderColor = '';
    @Input() textColor = '#fff';
    baseURL = ''
    contactFormId: string;

    constructor(
        private readonly afs: AngularFirestore,
        private ref: ChangeDetectorRef,
        private router: Router,
    ) {
        this.baseURL = this.router.url.split('/')[1];
        setInterval(() => {
            this.ref.markForCheck();
        }, 2000);
    }

    ngOnInit() {
        if (this.bucket) {
            this.checkPageAndAlterText(this.bucket.urlPath);
        }
    }

    switchForm(formName: string) {
        this.activeForm = formName;

        switch (formName) {
            case 'book':
                this.form = new FormGroup({
                    'name': new FormControl(this.book.name, [Validators.required, validateString]),
                    'email': new FormControl(this.book.email, [Validators.required, Validators.email],),
                    'telephone': new FormControl(this.book.telephone, [Validators.required, validateString]),
                    'message': new FormControl(this.book.message),
                });
                break;
            case 'book-golf':
                this.form = new FormGroup({
                    'name': new FormControl(this.book.name, [Validators.required, validateString]),
                    'email': new FormControl(this.book.email, [Validators.required, Validators.email],),
                    'telephone': new FormControl(this.book.telephone, [Validators.required, validateString]),
                    'date': new FormControl(this.book.date, [Validators.required, validateString]),
                    'time': new FormControl(this.book.time, [Validators.required, validateString]),
                    'noOfPlayers': new FormControl(this.book.noOfPlayers, [Validators.required, validateString]),
                });
                break;
            case 'book-meal':
                this.form = new FormGroup({
                    'name': new FormControl(this.book.name, [Validators.required, validateString]),
                    'email': new FormControl(this.book.email, [Validators.required, Validators.email],),
                    'telephone': new FormControl(this.book.telephone, [Validators.required, validateString]),
                    'date': new FormControl(this.book.date, [Validators.required, validateString]),
                    'time': new FormControl(this.book.time, [Validators.required, validateString]),
                    'noOfGuests': new FormControl(this.book.noOfGuests, [Validators.required, validateString]),
                });
                break;
            default:
        }
    }

    checkPageAndAlterText(url) {
        // const bookElement = document.getElementById('book-text');
        // if (this.router.url.includes('properties')) {
        //     bookElement.innerText = 'CONTACT AGENT';
        //     bookElement.style.top = '130px';
        // } else if (url.includes('golf')) {
        //     bookElement.innerText = 'BOOK A ROUND';
        //     bookElement.style.top = '120px';
        // } else {
        //     bookElement.innerText = 'CONTACT US';
        // }
    }

    toggleBooking() {
        this.slide = !this.slide;
        this.slide ?
            document.getElementById('book-main').style.transform = 'translateX(-92vw)' :
            document.getElementById('book-main').style.transform = 'translateX(0vw)';

        const animatableSideColumnStyle = (document.getElementsByClassName('animatable-side-column')[0] as HTMLElement).style;
        const mobileSideBarSlideStyle = document.getElementById('mobile-side-bar-slide').style;

        if (animatableSideColumnStyle.transform === 'translateX(-100vw)' || mobileSideBarSlideStyle.transform === 'translateX(-100%)') {
            const navBarMenuItems = document.querySelectorAll('.navbar-menu__item');
            const navBarMenuDivLines = document.querySelectorAll('.navbar-menu__div-line');
            const animatableSideColumns = document.querySelectorAll('.animatable-side-column');

            navBarMenuItems.forEach((navBarMenuItem) => {
                const navBarMenuItemStyle = (navBarMenuItem as HTMLElement).style;
                navBarMenuItemStyle.transitionDelay = '0s';
                navBarMenuItemStyle.transform = 'translateY(-30px)';
                navBarMenuItemStyle.opacity = '0';
            });

            navBarMenuDivLines.forEach((navBarMenuDivLine) => {
                const navBarMenuDivLineStyle = (navBarMenuDivLine as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.marginLeft = '30px';
                navBarMenuDivLineStyle.opacity = '0';
            });

            animatableSideColumns.forEach((animatableSideColumn) => {
                const navBarMenuDivLineStyle = (animatableSideColumn as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.transform = 'translateX(0)';
            });

            mobileSideBarSlideStyle.transform = 'translateX(0)';
        }
    }

    sendMail(type: string) {
        this.form.markAsTouched();
        this.book['type'] = type;

        if (this.form.valid && !this.formSubmitting) {
            if (type === 'subscribe') {
                this.createContactDocument();
            } else {
                this.book['type'] = this.activeForm;
                this.createContactDocument();
            }
        }
    }

    // getBookingType() {
    //     const url = this.bucket.urlPath;
    //     if (this.isGolfCheck(url)) {
    //         return 'book-golf';
    //     }
    //     if (this.isRestaurantCheck(url)) {
    //         return 'book-meal';
    //     }
    //     return 'book';
    // }

    createContactDocument() {
        this.formSubmitting = true;

        if (!this.book.message && this.activeForm === 'book') {
            this.book.message = null;
        } else if(this.activeForm === 'book-golf') {
            delete this.book.message;
            delete this.book.noOfGuests;
        } else if(this.activeForm === 'book-meal') {
            delete this.book.message;
            delete this.book.noOfPlayers;
        }

        this.afs.collection('contact')
            .add(this.book)
            .then(() => {

                if (this.book.type === 'subscribe') {
                    this.formSubmitting = false;
                } else {
                    this.slide = null;
                    this.form.reset();
                    this.formSubmitting = false;
                    this.subscribeChecked = false;
                    document.getElementById('book-main').style.transform = 'translateX(0)';
                }
            }).catch(() => {
        });
    }

    canSubscribe() {
        this.disableSubscribe = this.form.get('name').invalid || this.form.get('email').invalid;
    }

    formChange() {
        this.canSubscribe();
    }
}
