import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hexagon-label',
  templateUrl: './hexagon-label.component.svg',
  styleUrls: ['./hexagon-label.component.scss']
})
export class HexagonLabelComponent implements OnInit {

  @Input() background = "none";
  @Input() textColor = "#414141";
  @Input() text = "Empty";

  constructor() { }

  ngOnInit() {
  }

}
