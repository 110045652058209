import {Component, Input, OnInit} from '@angular/core';
import { fbButton, linkedin, pinterest, tw, whatsapp } from 'vanilla-sharing';

declare const window;

@Component({
  selector: 'share-button',
  templateUrl: './share-button.component.html'
})
export class ShareButtonComponent implements OnInit {
  @Input() title: any;
  @Input() description: any;
  @Input() imageUrl: any;
  @Input() url: any;
  showButtons = false;

  constructor() { }

  ngOnInit() {
  }

  shareFacebook() {
    fbButton({
      url: window.location.href
    })
  }

  sharePinterest() {
    pinterest({
      url: this.url || window.location.href,
      description: this.getDescription(this.title),
      media: this.imageUrl,
    });
  }

  shareWhatsapp() {
    whatsapp({
      url: this.url || window.location.href,
      title: this.getDescription(this.title),
      phone: '',
    });
  }

  shareTwitter() {
    tw({
      url: this.url || window.location.href,
      title: this.getDescription(this.title)
    })
  }

  shareLinkedIn() {
    linkedin({
      url: this.url || window.location.href,
      title: this.getDescription(this.title),
      description: this.title || '',
    })
  }
  
  private getDescription(title) {
    return 'Steyn City - ' + title || 'Steyn City';
  }
}
