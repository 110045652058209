import {ErrorHandler,NgModule,} from '@angular/core';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireFunctionsModule} from '@angular/fire/functions';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {
    BrowserModule,
    BrowserTransferStateModule
} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LottieModule } from 'ngx-lottie';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {UpdateService} from "./core/update.service";
import {UiModule} from './ui/ui.module';

// IMPORTANT
// Add your own project credentials to environments/*.ts

// Import Bugsnag and the Angular integration
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
// ... other imports omitted for brevity

// configure Bugsnag asap
const bugsnagClient = bugsnag('3b2b3dfe830b1b16d98bbf8bb8d685e4');

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(bugsnagClient)
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        UiModule,
        AngularFireModule.initializeApp(environment.firebase, 'firestarter'),
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireFunctionsModule,
        AngularFirestoreModule.enablePersistence(),
        ServiceWorkerModule.register('/ngsw-worker.js', {
            enabled: environment.production, registrationStrategy: 'registerImmediately'
        }),
        LottieModule
    ],
    providers: [UpdateService,{ provide: ErrorHandler, useFactory: errorHandlerFactory }, DeviceDetectorService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
