import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
    public sidebarVisible: boolean;
    isSearchOpen = false;
    public show = false;
    public searchString = "";
    public searchResults: any[] = [];
    siteData: any[];

    constructor(private readonly afs: AngularFirestore, private readonly router: Router) {
        this.sidebarVisible = false;
    }

    ngOnInit(): void {
        this.fetchSiteContent();
    }

    private fetchSiteContent() {
        this.afs.collection('pages', (x)=> x.where( 'active', '==', true ))
            .valueChanges()
            .subscribe(content => {
                this.siteData = content;    
            });
    }

    search() {
        this.searchResults = [];
        if (this.searchString.trim().length > 1) {
            this.siteData.forEach((blackBucket) => {
                const urlPath = blackBucket.urlPath;
                const blackBucketTitle: string = blackBucket.pageTitle || "";
                const blackBucketText: string = blackBucket.text || "";
                const blackBucketKeyWords: string = blackBucket.searchKeywords || "";
            
                if ((blackBucketKeyWords +" "+ blackBucketTitle +" "+ blackBucketText).toLowerCase().includes(this.searchString.toLowerCase())) {
                    this.searchResults.push({
                        urlPath,
                        pageTitle: blackBucketTitle,
                        image: blackBucket.primaryImageUrl,
                        keyword: !!blackBucket.searchKeywords
                    });
                }
                if (blackBucket.children) {
                    blackBucket.children.forEach((blueBucket) => {
                        const blueBucketTitle: string = blueBucket.title;
                        const blueBucketText: string = blueBucket.text;
                        const blueBucketKeyWords: string = blueBucket.searchKeywords || "";

                        if ((blueBucketKeyWords+" "+blueBucketTitle+" "+blueBucketText).toLowerCase().includes(this.searchString.toLowerCase())) {
                            this.searchResults.push({
                                id: blueBucket.id,
                                urlPath,
                                pageTitle: blackBucketTitle,
                                blueBucketTitle,
                                image: blueBucket.primaryImageUrl,
                                keyword: !!blueBucket.searchKeywords
                            });
                        }
                    });
                }
            });
        }

        this.searchResults.sort((a, b) => { 
            if(a.keyword === false && b.keyword === true) {
                return 1;
            }
            return -1; 
        });
    }

    closeSearch() {
        this.searchString = "";
        this.searchResults = [];
        const searchContainerStyle = document.getElementById('search-container').style;
        const searchContainerInputStyle = document.getElementById('input-container').style;
        const searchCloseStyle = document.getElementById('close-search-container').style;
        searchContainerStyle.transform = 'translateX(100vw)';
        searchContainerInputStyle.transform = 'translateX(100vw)';
        searchCloseStyle.transform = 'translateX(100vw)';
        searchContainerStyle.opacity = '0';
        searchContainerInputStyle.opacity = '0';
        searchCloseStyle.opacity = '0';
        (document.querySelector('#search-container #input-container input') as HTMLElement).blur();
    }
}
