import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ActivatedRoute, NavigationStart, Router} from "@angular/router";
import * as _ from "lodash";
import {Subscription} from 'rxjs';
import {ElementScrollPercentage} from "../../element-scroll-percentage";

declare const window;
declare var $: any;

@Component({
    selector: 'home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
    pageData: any[];

    public innerScroll: number;
    public pageScroll: number;
    public demoRange: number[];
    private pageRef: Subscription;
    private routeRef: Subscription;

    constructor(private readonly afs: AngularFirestore, private readonly elementScrollPercentage: ElementScrollPercentage, private readonly route: ActivatedRoute, private readonly router: Router) {
        this.demoRange = _.range(15);
        this.innerScroll = 0;
        this.pageScroll = 0;

        let url = 'home';

        if (this.route.snapshot.url && this.route.snapshot.url.length > 0) {
            url = this.route.snapshot.url[0].path;
        }

        this.fetchContent(url);

        this.routeRef = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // Show loading indicator
                const routeUrl = event.url.substring(1);
                this.fetchContent(routeUrl);
            }
        });
    }

    ngOnInit() {

    }

    ngAfterViewInit(): void {
        this.elementScrollPercentage
            .getScrollAsStream() // Defaults to Document if no Element supplied.
            .subscribe(
                (percent: number): void => {
                    this.pageScroll = percent;
                }
            );
    }

    ngOnDestroy(): void {
        this.pageRef.unsubscribe();
        this.routeRef.unsubscribe();
    }

    private fetchContent(doc) {
        let documentId = doc.split('#')[0];

        if (documentId.length === 0) {
            documentId = 'home';
        }

        this.pageRef = this.afs.collection('pages', ref => ref.where('urlPath', '==', documentId))
            .valueChanges()
            .subscribe(content => {
                if (!content || content.length === 0) {
                    console.log('test')
                    window.location.href = '/404.html';
                }

                if (content && content[0] && content[0]['pageTitle'] && String(content[0]['pageTitle']).toLocaleLowerCase() === "home") {
                    this.shuffleBlueBuckets(content[0]['children']);
                }

                this.pageData = content;
            }, error => {
                console.error(error);
            });
    }

    private shuffleBlueBuckets(children: any[]) {

        for (let index = 0; index < (children.length - 2); index++) {
            const randNumber = Math.floor((Math.random() * Number(Number(children.length) - 2)));
            const temp = JSON.parse(JSON.stringify(children[index]));
            children[index] = JSON.parse(JSON.stringify(children[randNumber]));
            children[randNumber] = JSON.parse(JSON.stringify(temp));
        }

        // stagger
        for (let index = 1; index < (children.length - 1); index++) {
            const temp = children[index];
            (children[index - 1].type === 'text-left') ? temp.type = 'text-right' : temp.type = 'text-left';
        }
    }

    getWindowTopY() {
        return window.pageYOffset;
    }
}
