import activities from './activities-large.svg';
import facilities from './facilities-large.svg';
import nature from './nature-large.svg';
import outdoor from './outdoor-large.svg';
import swimmingLarge from './swimming-large.svg';
import security from './security-large.svg';

import skating from './skate-activities.svg';
import kite from './kite-activities.svg';
import trail from './trail-activities.svg';
import windsurfing from './windsurf-activities.svg';
import yoga from './yoga-activities.svg';
import hiking from './hiking-activities.svg';

import golf from './golf-club.svg';
import gym from './gym-club.svg';
import picnic from './picnic-club.svg';
import tennis from './tennis-club.svg';
import wine from './wine-club.svg';

import cycling from './cycling-outdoor.svg';
import painting from './painting-outdoor.svg';
import plant from './planting-outdoor.svg';
import swimming from './swimming-outdoor.svg';
import walking from './walking-outdoor.svg';

import eateries from './eateries-centre.svg';
import hangar from './hangar-centre.svg';
import helipad from './helipad-centre.svg';
import petrol from './petrol-centre.svg';
import swimmingCentre from './swimming-centre.svg';
import shield from './shield-security.svg';

import triangle from './triangle.svg';

export default {
    activities,
    facilities,
    nature,
    outdoor,
    swimmingLarge,
    security,
    skating,
    kite,
    trail,
    windsurfing,
    yoga,
    hiking,
    golf,
    gym,
    picnic,
    tennis,
    wine,
    cycling,
    painting,
    plant,
    swimming,
    walking,
    eateries,
    hangar,
    helipad,
    petrol,
    swimmingCentre,
    shield,
    triangle
}