import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from "lodash";
import imgSource from '../../../../../assets/images/100-reasons-dialog-images';

const data = {
    "01": `Strung along the promenade and walkways are several interactive play zones, each with an array of equipment designed to stimulate creativity and learning through play.`,
    "08": `Our fondest memories are the ones that bubble up from that magical time when our worlds were free of the concerns that clutter and cloud the minds of grown-ups. Perhaps what inspired us to create such an idyllic outdoor wonderland for the young was our desire to recapture that sense of limitless freedom. `,
    "13": `The magnificent indoor aquatic training facility’s learn to swim pool caters for little mermaids (and mermen) aged six months to 10 years to ensure proficient and happy swimmers. This facility is open for use only when a group or private session has been scheduled with a swimming coach from our swim school.`,
    "14": `45km of pedestrian boulevard provides easy access to the surrounding parkland. You can walk, run or ride on the paths and walkways that wind their way through grasslands, wooded areas and along the banks of the Jukskei River. `,
    "20": `Steyn City’s 2 000-acre backyard is traversed by kilometres of running and cycling track and outdoor workout stations, while the parkland’s facilities further include resort pools, golf course, gym, tennis courts, equestrian and aquatic centre.`,
    "23": `Our world-class Equestrian Centre, complete with Olympic size outdoor all-weather arena (the largest in the country), boasts the finest equine facilities, including a clubhouse, means you can make horse-riding a part of your daily lifestyle. `,
    "28": `Nicknamed the Augusta of South Africa, Steyn City’s championship 18-hole Nicklaus designed championship course ranks amongst the very best. A par 72 over 7 000 metres from the tees, all greens are built to USGA specs.  `,
    "39": `The course has 12km of golf cart paths and is fully navigable. All carts feature state-of-the-art GPS technology for safety and convenience.        `,
    "43": `With a magnificent design that mirrors the curves of the surrounding landscape, the Clubhouse’s spacious outdoor piazza presents breath-taking views where you can enjoy a sundowner and is home to our gourmet XIX restaurant.`,
    "60": `Steyn City has a private security force of 100 personnel. The Estate can only be entered and exited through one of two gatehouses and nn electronic tag-scan system confirms the identity of residents’ cars.  `,
    "61": `Wandering through the parklands, you may chance upon a giant resting next to the walkway, or see a wall covered in mosaics that radiates a flurry of colours and textures. These land art pieces have been created by a team of local artists led by a well-known ceramic sculptor, who have used the landscape as the backdrop for their creative works. `,
    "62": `With wetlands, grasslands and densely wooded areas restored to a pristine state, the lifestyle resort is becoming a haven for birdlife, where adults and children alike can spend hours discovering a fascinating world.        `,
    "63": `With only 50% of Steyn City zoned for development, residents live in a natural paradise, enhanced by the planting of a million trees, shrubs and ground cover to create Johannesburg’s most extensive woodland.  Forty-five kilometres of pedestrian boulevards, paths and walkways, provide unrestricted access to the surrounding parklands `,
    "64": `Manicured greenbelts stretch between each home, with verdant natural expanses criss-crossed by waterways, paths, urban artscapes and scenic spots all adding to the parkland’s unique setting. `,
    "72": `With only 50% of Steyn City zoned for development, residents live in a natural paradise, enhanced by the planting of a million trees, shrubs and ground cover to create Johannesburg’s most extensive woodland. `,
    "74": `Walk, run, or ride 45km of a well-lit pedestrian boulevard that winds through the parklands, dams and creeks, and of course, the Steyn City golf course where even non-golfers share in the scenic beauty of our fairways. `,
    "79": `The resort pools, the spas, the indoor (and outdoors) gyms, the private sandy coves along the shores of the southern hemisphere’s largest pool, the exclusive speciality retail stores and dining options – around here the is no shortage of ways to pamper yourself. `,
    "84": `Many of us dream of spending long lazy days lounging beside a sparkling lagoon. For others, the antidote to stress is walking, carefree, along a quiet, wooded country path. And then there are those who would prefer the challenge of a few sets on the court, or a drive down a dog-leg fairway. `,
    "85": `Who needs to venture to the coast to experience the beach? Steyn City’s fully-serviced Private Beach Club is located on the shores of a massive 300m clearwater lagoon, providing the idyllic beach experience in Gauteng. It’s the ultimate quick getaway.`,
    "91": `The first of its kind in the property sector, the residents’ app makes it possible to book for all facilities in-app, track visitors within the resort in real-time, scan and locate your missing pet or plot a trail run that suits both your fitness level and the time you have available, plus so much more. `,
    "98": `Capital Park, our new commercial park overlooks Steyn City’s creek as well as beautiful landscaping, offering spectacular views and a lush environment. Tenants are enveloped in tranquility and encircled by breath-taking views. Whether you live and work in Steyn City or are fortunate enough to have your office here, this is a work environment like none other that will simply take your breath away. `,
    "99": `The ultimate convenience: walking distance from your home. An independent member of SAHISA, Steyn City School is geared towards preparing children for a changing world. Steyn City Schools accommodates learners from Grade 000 to Grade 11, and Matric in the year 2021. `,
    "100": `Every great city has a heart - a centre that pulsates with life and activity. Steyn City is no exception. The difference, of course, is that our City Centre is designed for luxurious living from the bottom up. This includes an eight hectare super basement area beneath the City to limit access above ground exclusively for pedestrians. `,
}

@Component({
    selector: 'hundred-reasons-dialog',
    templateUrl: './hundred-reasons-dialog.component.html',
    styleUrls: ['./hundred-reasons-dialog.component.scss'],
})
export class HundredReasonsDialogComponent implements OnInit {

    @Input() currentElement: any;
    @Output() Close = new EventEmitter();

    images: any;
    descriptions: any;

    constructor() {
        this.images = imgSource;
        this.descriptions = data;
    }

    cleanHeaderText(str) {
        return str.replace(/[~]/g, ' ').toUpperCase();
    }

    ngOnInit() {
       
    }

    onClose() {
        this.Close.emit('close');
    }

}
