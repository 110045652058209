import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss'],
})
export class MainNavComponent {
    public sidebarVisible: boolean;
    public sidebarItems: string[];
    public bucketPages: any[];
    public focusPages: any[];
    public showMainMenu = true;
    public showContact = false;
    public show = false;
    pageYOffset: number;
    public isProperties;
    isPropertiesMenuVisible = false;
    isTouchDevice = false;

    constructor(private afs: AngularFirestore, private router: Router) {
        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        }

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                // if you need to scroll back to top, here is the right place
                window.scrollTo(0, 0);
            }
        });
        this.sidebarVisible = false;
        this.isProperties = window.location.href.indexOf('properties') > 0;
        this.fetchContent();
    }

    sidebarOpen() {
        this.sidebarVisible = true;
        this.showMainMenu = null;
    }

    sidebarClose() {
        this.sidebarVisible = false;
        this.showMainMenu = null;
        this.showContact = false;
    }

    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.closeBookingWindow(); // close other windows, when menu opens
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
        this.sideBarAnimation();
        this.contactsAnimation();

        this.customScrollbar(false, false, false)
    }

    mobileSidebarToggle() {
        const mobileSideBarSlideStyle = document.getElementById('mobile-side-bar-slide').style;

        mobileSideBarSlideStyle.transform = mobileSideBarSlideStyle.transform !== 'translateX(-100%)' ?
            'translateX(-100%)' : 'translateX(0)';
    }

    searchToggle() {
        const searchContainerStyle = document.getElementById('search-container').style;
        const searchContainerInputStyle = document.getElementById('input-container').style;
        const searchCloseStyle = document.getElementById('close-search-container').style;

        if (searchContainerStyle.opacity !== '1') {
            (document.querySelector('#search-container #input-container input') as HTMLInputElement).focus();
            searchContainerStyle.transform = 'translateX(0)';
            searchContainerStyle.opacity = '1';
            searchContainerInputStyle.transform = 'translateX(0)';
            searchContainerInputStyle.opacity = '1';
            searchCloseStyle.transform = 'translateX(0)';
            searchCloseStyle.opacity = '1';
        } else {
            searchContainerStyle.transform = 'translateX(100vw)';
            searchContainerStyle.opacity = '0';
            searchContainerInputStyle.transform = 'translateX(100vw)';
            searchContainerInputStyle.opacity = '0';
            searchCloseStyle.transform = 'translateX(100vw)';
            searchCloseStyle.opacity = '0';
            (document.querySelector('#search-container #input-container input') as HTMLInputElement).value = '';
            (document.querySelector('#search-container #input-container input') as HTMLInputElement).blur();
        }
    }

    closeBookingWindow() {
        if (document.getElementById('book-main')) {
            document.getElementById('book-main').style.transform = 'translateX(0vw)';
        }
    }

    toggleSubscribe (open: boolean = true) {
        const subscribeDialogContainerStyle = document.getElementById('subscribe-dialog-container').style;
        if (open) {
            subscribeDialogContainerStyle.transform = 'translateX(0)';
            subscribeDialogContainerStyle.opacity = '1';
        } else {
            subscribeDialogContainerStyle.transform = 'translateX(100vw)';
            subscribeDialogContainerStyle.opacity = '0';
        }
    }

    sideBarAnimation() {
        const navBarMenuItems = document.querySelectorAll('.navbar-menu__item');
        const navBarMenuDivLines = document.querySelectorAll('.navbar-menu__div-line');
        const animatableSideColumns = document.querySelectorAll('.animatable-side-column');

        if (this.sidebarVisible === false) {
            navBarMenuItems.forEach((navBarMenuItem, i) => {
                const navBarMenuItemStyle = (navBarMenuItem as HTMLElement).style;
                navBarMenuItemStyle.transitionDelay = '0s';
                navBarMenuItemStyle.transform = 'translateY(-30px)';
                navBarMenuItemStyle.opacity = '0';
            });

            navBarMenuDivLines.forEach((navBarMenuDivLine, i) => {
                const navBarMenuDivLineStyle = (navBarMenuDivLine as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.marginLeft = '30px';
                navBarMenuDivLineStyle.opacity = '0';
            });

            animatableSideColumns.forEach((animatableSideColumn, i) => {
                const navBarMenuDivLineStyle = (animatableSideColumn as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.transform = 'translateX(0)';
            });
        } else {
            navBarMenuItems.forEach((navBarMenuItem, i) => {
                const navBarMenuItemStyle = (navBarMenuItem as HTMLElement).style;
                navBarMenuItemStyle.transitionDelay = (i / 16) + 's';
                navBarMenuItemStyle.transform = 'translateY(0)';
                navBarMenuItemStyle.opacity = '1';

                navBarMenuItem.addEventListener('mouseover', (mouseEvent) => {
                    (mouseEvent.target as HTMLElement).style.transform = 'scale(1.3)';
                    navBarMenuItemStyle.transitionDelay = 0 + 's';
                }, {passive: true});

                navBarMenuItem.addEventListener('mouseout', (mouseEvent) => {
                    (mouseEvent.target as HTMLElement).style.transform = 'scale(1)';
                    navBarMenuItemStyle.transitionDelay = 0 + 's';
                }, {passive: true});
            });

            navBarMenuDivLines.forEach((navBarMenuDivLine, i) => {
                const navBarMenuDivLineStyle = (navBarMenuDivLine as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = (0.9 + (i / 2)) + 's';
                navBarMenuDivLineStyle.marginLeft = '0';
                navBarMenuDivLineStyle.opacity = '1';
            });

            animatableSideColumns.forEach((animatableSideColumn, i) => {
                const navBarMenuDivLineStyle = (animatableSideColumn as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = (((animatableSideColumns.length - 1) - i) / 9) + 's';
                navBarMenuDivLineStyle.transform = 'translateX(' + (-100 + ((100 / animatableSideColumns.length) * i)) + 'vw)';
            });
        }
    }

    contactsAnimation() {
        const sideBarContactDetails = document.querySelectorAll('#side-bar-contact .col-6 .contact-container');

        sideBarContactDetails.forEach((sideBarContactDetail, i) => {
            const sideBarContactDetailStyle = (sideBarContactDetail as HTMLElement).style;
            sideBarContactDetailStyle.transitionDelay = (i / 7) + 's';

            if (this.showContact === false) {
                sideBarContactDetailStyle.transform = 'translateY(-100px)';
                sideBarContactDetailStyle.opacity = '0';
            } else {
                sideBarContactDetailStyle.transform = 'translateY(0)';
                sideBarContactDetailStyle.opacity = '1';
            }
        });
    }

    customScrollbar(up: boolean, down: boolean, scrolling: boolean) {
        const scrollableElement = document.getElementsByClassName('contact-us-container-overflow')[0];
        const scrollBarHeight = document.getElementById('scroll-bar').clientHeight;
        const scrollIndicator = document.getElementById('scroll-bar-indicator');
        const scrollableScrollPosition = scrollableElement.scrollTop;
        const scrollBy = 50;

        if (scrollableElement.scrollHeight > scrollableElement.clientHeight) {
            document.getElementById('contact-us-scroller').style.display = 'unset';
            const scrollPosition = (scrollBarHeight *
                (scrollableScrollPosition / (scrollableElement.scrollHeight - scrollableElement.clientHeight))
            );

            if (up) {
                scrollableElement.scrollTo(0, scrollableScrollPosition - scrollBy);
            } else if (down) {
                scrollableElement.scrollTo(0, scrollableScrollPosition + scrollBy);
            } else if (scrolling) {
                scrollIndicator.style.transform = 'translateY(' + scrollPosition + 'px)';
            }
        } else {
            document.getElementById('contact-us-scroller').style.display = 'none';
        }
    }

    private fetchContent() {
        this.afs.collection('pages', ref => ref.orderBy('sortOrder')).valueChanges()
            .subscribe((content: any[]) => {
                this.bucketPages = content.filter(item => !item.isFocus && item.active);
                this.focusPages = content.filter(item => !!item.isFocus && item.active);
            });
    }

    openContactUs() {
        this.showMainMenu = this.showMainMenu === null ? false : !this.showMainMenu;

        this.showContact = !this.showContact;

        this.contactsAnimation();
    }

    mobileToggleContactUs() {
        const mobileSideBarPanelSlideStyle = document.getElementById('mobile-side-bar-panel-slide').style;
        const mobileToggleContactUsSlide = document.getElementById('mobile-toggle-contact-us-slide').style;

        if (mobileSideBarPanelSlideStyle.transform !== 'translateX(calc(-100% - 36px))') {
            mobileSideBarPanelSlideStyle.transform = 'translateX(calc(-100% - 36px))';
            mobileToggleContactUsSlide.transform = 'translateY(-24px)';
        } else {
            mobileSideBarPanelSlideStyle.transform = 'translateX(0)';
            mobileToggleContactUsSlide.transform = 'translateX(0)';
        }
    }

    getWindowTopY() {
        this.pageYOffset = window.pageYOffset;
        return window.pageYOffset;
    }

    togglePropertiesMenu() {
        this.isPropertiesMenuVisible = !this.isPropertiesMenuVisible;
    }

    checkTouchDevice() {
        try {
            document.createEvent('TouchEvent');
            this.isTouchDevice = true;
        } catch (e) {
            this.isTouchDevice = false;
        }
    }
}
