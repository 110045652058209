import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hexagon-icon',
  templateUrl: './hexagon-icon.component.html',
  styleUrls: ['./hexagon-icon.component.scss']
})
export class HexagonIconComponent implements OnInit {

  @Input() svg = "";
  @Input() size = "small";

  constructor() { }

  ngOnInit() {
  }

}
