import img_01 from './img_01.jpg';
import img_02 from './img_02.jpg';
import img_03 from './img_03.jpg';
import img_04 from './img_04.jpg';
import img_05 from './img_05.jpg';
import img_06 from './img_06.jpg';

export default {
   image_01: img_01,
   image_02: img_02,
   image_03: img_03,
   image_04: img_04,
   image_05: img_05,
   image_06: img_06,
}
