import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import {interval} from "rxjs";

@Injectable()
export class UpdateService {

    constructor(private readonly swUpdate: SwUpdate) {
        if (this.swUpdate.isEnabled) {
            interval(6 * 60 * 60).subscribe(() => this.swUpdate.checkForUpdate()
                .then(() => {}));


        }

        this.swUpdate.available.subscribe(evt => {
            const confirm = window.confirm('There is a new update to this website. Would you like to reload?');

            if (confirm === true) {
                window.location.reload();
            }
        });
    }

    checkForUpdate() {
        if (!navigator || !navigator.serviceWorker || navigator.serviceWorker.controller === null) {
            return;
        }
        this.swUpdate.checkForUpdate();
    }
}
