import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'hexagon-item',
  templateUrl: './hexagon.component.svg',
  styleUrls: ['./hexagon.component.scss'],
})
export class HexagonComponent implements OnInit {

  @Input() background = "none";
  @Input() borderColor = "#786a5d";
  @Input() textColor = "#fff";
  @Input() text = "Empty";
  @Input() number = "33";
  @Input() numberColor = "#786a5d";
  @Input() backgroundOpacity = "0.496";
  @Input() clickable = false;
  @Input() comingSoon = false;
  havored = false;

  constructor() {
   }

  ngOnInit() {
  }

  onHover() {
   
    if(this.clickable) {
    this.backgroundOpacity = "0.7";
    this.borderColor = "#fff";
    this.havored = true;
    }
  }

  onLeave() {
    if(this.clickable) {
    this.backgroundOpacity = "0.5";
    this.borderColor = "#786a5d";
    this.havored = false;
    }
  }

}
