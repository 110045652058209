import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

function validateString(c: FormControl) {
    const REGEX = /.*\S.*/;
    return REGEX.test(c.value) ? null : {
        validateString: {
            valid: false
        }
    };
}

@Component({
    selector: 'contact-agent',
    templateUrl: './contact-agent.component.html',
    styleUrls: ['./contact-agent.component.scss']
})
export class ContactAgentComponent implements OnInit {
    book: any = {};
    form: FormGroup;
    formSubmitting = false;
    slide = false;
    subscribeChecked = false;
    activeForm: string = null;
    disableSubscribe = true;
    baseURL = ''
    contactFormId: string;

    constructor(
        private readonly afs: AngularFirestore,
        private ref: ChangeDetectorRef,
        private router: Router,
    ) {
        this.baseURL = this.router.url.split('/')[1];
        setInterval(() => {
            this.ref.markForCheck();
        }, 2000);
    }

    ngOnInit() {
        this.form = new FormGroup({
            'name': new FormControl(this.book.name, [Validators.required, validateString]),
            'email': new FormControl(this.book.email, [Validators.required, Validators.email],),
            'telephone': new FormControl(this.book.telephone, [Validators.required, validateString]),
            'message': new FormControl(this.book.message),
        });
    }

    toggleBooking() {
        this.slide = !this.slide;
        this.slide ?
            document.getElementById('contact-agent-form').style.transform = 'translateX(-100vw)' :
            document.getElementById('contact-agent-form').style.transform = 'translateX(0vw)';

        const animatableSideColumnStyle = (document.getElementsByClassName('animatable-side-column')[0] as HTMLElement).style;
        const mobileSideBarSlideStyle = document.getElementById('mobile-side-bar-slide').style;

        if (animatableSideColumnStyle.transform === 'translateX(-100vw)' || mobileSideBarSlideStyle.transform === 'translateX(-100%)') {
            const navBarMenuItems = document.querySelectorAll('.navbar-menu__item');
            const navBarMenuDivLines = document.querySelectorAll('.navbar-menu__div-line');
            const animatableSideColumns = document.querySelectorAll('.animatable-side-column');

            navBarMenuItems.forEach((navBarMenuItem) => {
                const navBarMenuItemStyle = (navBarMenuItem as HTMLElement).style;
                navBarMenuItemStyle.transitionDelay = '0s';
                navBarMenuItemStyle.transform = 'translateY(-30px)';
                navBarMenuItemStyle.opacity = '0';
            });

            navBarMenuDivLines.forEach((navBarMenuDivLine) => {
                const navBarMenuDivLineStyle = (navBarMenuDivLine as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.marginLeft = '30px';
                navBarMenuDivLineStyle.opacity = '0';
            });

            animatableSideColumns.forEach((animatableSideColumn) => {
                const navBarMenuDivLineStyle = (animatableSideColumn as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.transform = 'translateX(0)';
            });

            mobileSideBarSlideStyle.transform = 'translateX(0)';
        }
    }

    sendMail(type: string) {
        this.form.markAsTouched();
        this.book['type'] = type;

        if (this.form.valid && !this.formSubmitting) {
            this.createContactDocument();
        }
    }

    createContactDocument() {
        this.formSubmitting = true;

        if (!this.book.message && this.activeForm === 'book') {
            this.book.message = null;
        }

        this.afs.collection('contact')
            .add(this.book)
            .then(() => {

                if (this.book.type === 'subscribe') {
                    this.formSubmitting = false;
                } else {
                    this.slide = null;
                    this.form.reset();
                    this.formSubmitting = false;
                    this.subscribeChecked = false;
                    document.getElementById('contact-agent-form').style.transform = 'translateX(0)';
                }
            }).catch(() => {
        });
    }

    canSubscribe() {
        this.disableSubscribe = this.form.get('name').invalid || this.form.get('email').invalid;
    }

    formChange() {
        this.canSubscribe();
    }
}
