import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import smallSVGFiles from '../../../../../assets/images/100-reasons-svg';
import { Router } from '@angular/router';

@Component({
  selector: 'hexagon-grid',
  templateUrl: './hexagon-grid.component.html',
  styleUrls: ['./hexagon-grid.component.scss'],
})

export class HexagonGridComponent implements OnInit {

  @Output() Click = new EventEmitter();
  svg: any = {}; 

  color = {
    white: '#fff',
    brown : '#FF5F6B',
    core: '#786a5d',
    yellow: '#FFEA48',
    yellowGreen: '#C2DE24',
    creamWhite: '#CBD1D9',
    lightGreen: '',
    darkGreen: '#74DD7C',  
    blue: '#8BD7F8',
    none: 'none',
    purple: '#C26FFF'
  }

  constructor(protected readonly router: Router) {
    this.svg = smallSVGFiles;
  }

  ngOnInit() {
  }

  onSelect($selected) {
      this.Click.emit($selected);
  }

  onNavigate(url) {
    this.router.navigate(['',url]);
  }
}
