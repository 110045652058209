import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'bucket-blue',
    templateUrl: './bucket-blue.component.html',
    styleUrls: ['./bucket-blue.component.scss']
})
export class BucketBlueComponent implements OnInit {
    @Input() bucket: any;
    @Input() index: any;

    options: any;
    url = window.location.href;
    isMobile: boolean;

    constructor() {
    }

    ngOnInit() {
        if (this.bucket.logoImageUrl && this.bucket.logoImageUrl.indexOf('lottie') > -1) {
          this.options = {};
            this.options.path = this.bucket.logoImageUrl;
            this.options.loop = false;
        }

        this.bucket.isVideo = this.bucket.isVideo === undefined ?
            this.bucket.primaryImageUrl.includes('videos') :
            this.bucket.isVideo;

        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 768;
        }, {passive: true });
    }

    openBucket(bucket: any) {
        if (bucket.link) {
            bucket.link.includes('http') ?
                window.open(bucket.link, '_blank') :
                window.location.href = '/' + bucket.link;
        }
    }
}
