import { AfterContentInit, AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LottieOptions } from 'ngx-lottie';
import * as simpleParallax from 'simple-parallax-js';

declare const $: any;
declare const window: any;

@Component({
    selector: 'bucket-black',
    templateUrl: './bucket-black.component.html',
    styleUrls: ['./bucket-black.component.scss']
})
export class BucketBlackComponent implements OnInit, AfterContentInit, AfterViewInit {
    @Input() bucket: any;

    options: LottieOptions = {
        path: 'https://firebasestorage.googleapis.com/v0/b/steyn-city-web.appspot.com/o/lottie%2Fyourworld.json?alt=media&token=0045fec7-2fa4-4f2b-9fc6-7f5019a8e2c6',
        loop: false
    };
    slideConfig = {
        speed: 1500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        prevArrow: false,
        nextArrow: false,
        pauseOnHover: false,
    };

    isMobile = false;

    constructor(private readonly route: ActivatedRoute) {
    }

    ngOnInit() {

    }

    ngAfterContentInit(): void {
        window.addEventListener('scroll', this.scroll, {passive: true});
        this.evaluateScroll();

        // change bottom decal background image based on the top bucket
        setTimeout(() => { 
        const lastBucketColor = $('.sc-background-' + ($('bucket-blue').length)).css('background-color');
        $('#bucket-decal-svg').attr('style', `fill: ${lastBucketColor} !important`);
        }, 500);
    }

    ngAfterViewInit(): void {
       
    }

    evaluateScroll() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            const htmlStyle = document.getElementsByTagName('html')[0].style;
            htmlStyle.scrollBehavior = 'unset';
            window.scrollTo(0, 0);

            if (window.location.hash !== '') {
                htmlStyle.scrollBehavior = 'smooth';
                window.scrollTo(0, document.getElementById((window.location.hash).split('#')[1]).offsetTop);
                htmlStyle.scrollBehavior = 'unset';
            }
        }, 300);
    }

    scrollDown() {
        window.scrollTo({
            top: window.innerHeight + window.pageYOffset
            , behavior: 'smooth', 
        });
    }

    scroll = (): void => {
        const downAction = document.getElementsByClassName('down-action')[0] as HTMLElement;
        if(!!downAction) {
        ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - document.getElementsByClassName('footer')[0].getBoundingClientRect().height)) ?
            downAction.style.display = "none" : 
            downAction.style.display = "block";
        }
    };

    checkMobile() {
        this.isMobile = !!navigator.userAgent.match(/iphone|android|blackberry|mobile|phone|opera mini/ig) || false;
    }
}
