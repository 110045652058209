import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSegment } from '@angular/router';
import { HomePageComponent } from './ui/home-page/home-page.component';
import { HundredReasonsWhyComponent } from './ui/home-page/hundred-reasons-why/hundred-reasons-why.component';
import { PrivacyPolicyComponent } from './ui/home-page/privacy-policy/privacy-policy.component';
import { PropertiesComponent } from './ui/home-page/properties/properties.component';
import { SsrPageComponent } from './ui/ssr-page/ssr-page.component';

export function propertyMatcher(url: UrlSegment[]) {
    if (url && url.length > 0 && url[0].path !== 'null') {
        return url[0].path.startsWith('properties') ? ({consumed: url}) : null;
    }
    return null;
}

const routes: Routes = [
    // {path: 'uploads', component: UploadPageComponent, canActivate: [AuthGuard]},
    {path: 'ssr', component: SsrPageComponent},
    {path: 'admin', loadChildren: () => import('./ui/admin-portal/admin-portal.module').then(m => m.AdminPortalModule)},
    {path: 'admin/page/:id', loadChildren: () => import('./ui/admin-page/admin-page.module').then(m => m.AdminPageModule)},
    {pathMatch: 'prefix', component: PropertiesComponent, matcher: propertyMatcher},
    {path: 'active', pathMatch: 'prefix', component: HomePageComponent},
    {path: 'luxury', pathMatch: 'prefix', component: HomePageComponent},
    {path: 'nature', pathMatch: 'prefix', component: HomePageComponent},
    {path: 'convenience', pathMatch: 'prefix', component: HomePageComponent},
    {path: '100-reasons-why', pathMatch: 'prefix', component: HundredReasonsWhyComponent},
    {path: 'privacy-policy', pathMatch: 'prefix', component: PrivacyPolicyComponent},
    {path: '**', pathMatch: 'prefix', component: HomePageComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', initialNavigation: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
