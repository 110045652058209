import {Component, Input, OnInit} from '@angular/core';
import {NguCarouselConfig} from "@ngu/carousel";

@Component({
    selector: 'bucket-red',
    templateUrl: './bucket-red.component.html',
    styleUrls: ['./bucket-red.component.scss']
})
export class BucketRedComponent implements OnInit {
    @Input() buckets: any[] = [];
    @Input() index: any;

    public carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 2, md: 3, lg: 3, all: 0 },
        slide: 1,
        speed: 1500,
        point: {
            visible: true
        },
        load: 0,
        velocity: 0,
        loop: true,
        touch: true,
        easing: 'ease-in-out',
        interval: {
            initialDelay: 0,
            timing: 3000
        },
    };

    slideConfig = {
        dots: true,
        // autoplay: true,
        autoplaySpeed: 3000,
        speed: 1500,
        slidesToShow: 3,
        slidesToScroll: 3,
        // centerMode: true,
        centerPadding: '80px',
        infinite: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ],
    };

    url = window.location.href;

    public showMoreData: any;

    redBuckets: any[] = [];
    greenBuckets: any[] = [];
    yellowBuckets: any[] = [];
    slide = false;

    constructor() {}

    ngOnInit() {
        if (this.buckets) {
            this.redBuckets = this.buckets.filter((r) => r.isGreen === undefined && r.isYellow === undefined);
            this.greenBuckets = this.buckets.filter((g) => g.isGreen !== undefined);
            this.yellowBuckets = this.buckets.filter((y) => y.isYellow !== undefined);
        }

        if (this.buckets !== undefined) {
            this.buckets.forEach((bucket) => {
                bucket.isVideo = bucket.isVideo === undefined && bucket.primaryImageUrl !== undefined ?
                    bucket.primaryImageUrl.includes('videos') :
                    bucket.isVideo;
            });
        }
    }

    scrollToMap() {
        document.getElementById("map-container").scrollIntoView();
    }

    toggleBooking() {
        this.slide = !this.slide;
        this.slide ?
            document.getElementById('contact-agent-form').style.transform = 'translateX(-100vw)' :
            document.getElementById('contact-agent-form').style.transform = 'translateX(0vw)';

        const animatableSideColumnStyle = (document.getElementsByClassName('animatable-side-column')[0] as HTMLElement).style;
        const mobileSideBarSlideStyle = document.getElementById('mobile-side-bar-slide').style;

        if (animatableSideColumnStyle.transform === 'translateX(-100vw)' || mobileSideBarSlideStyle.transform === 'translateX(-100%)') {
            const navBarMenuItems = document.querySelectorAll('.navbar-menu__item');
            const navBarMenuDivLines = document.querySelectorAll('.navbar-menu__div-line');
            const animatableSideColumns = document.querySelectorAll('.animatable-side-column');

            navBarMenuItems.forEach((navBarMenuItem) => {
                const navBarMenuItemStyle = (navBarMenuItem as HTMLElement).style;
                navBarMenuItemStyle.transitionDelay = '0s';
                navBarMenuItemStyle.transform = 'translateY(-30px)';
                navBarMenuItemStyle.opacity = '0';
            });

            navBarMenuDivLines.forEach((navBarMenuDivLine) => {
                const navBarMenuDivLineStyle = (navBarMenuDivLine as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.marginLeft = '30px';
                navBarMenuDivLineStyle.opacity = '0';
            });

            animatableSideColumns.forEach((animatableSideColumn) => {
                const navBarMenuDivLineStyle = (animatableSideColumn as HTMLElement).style;
                navBarMenuDivLineStyle.transitionDelay = '0s';
                navBarMenuDivLineStyle.transform = 'translateX(0)';
            });

            mobileSideBarSlideStyle.transform = 'translateX(0)';
        }
    }
}
