import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'book-tour-button',
  templateUrl: './book-tour-button.component.svg',
  styleUrls: ['./book-tour-button.component.scss']
})
export class BookTourButtonComponent implements OnInit {

  @Input() background = "";
  @Input() borderColor = "";

  constructor() { }

  ngOnInit() {
  }

}
