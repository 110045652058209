import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import { Router } from '@angular/router';

declare var $: any;

@Component({
    selector: 'properties-page',
    templateUrl: './properties.component.html',
    styleUrls: ['./properties.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PropertiesComponent implements OnInit, AfterViewInit, OnDestroy {
    public pageScroll: number;
    public iframeLocation: SafeResourceUrl;
    public isPropertiesPage: boolean;

    constructor(private location: Location, private router: Router, public sanitizer: DomSanitizer) {
        const newUrlPath = this.router.url.replace('/properties', '');
        this.iframeLocation = sanitizer.bypassSecurityTrustResourceUrl(`https://properties.steyncity.co.za${newUrlPath}`);

        this.isPropertiesPage = this.router.url.startsWith('/properties');
    }

    ngOnInit() {
        document.querySelector('.nav-top-trans').classList.add('properties');
        document.querySelector('.navbar-nav.mr-auto').classList.add('properties');
        (document.querySelector('.navbar-item>div') as HTMLElement).style.display = 'none';
        (document.querySelector('.navbar-item img.properties') as HTMLElement).style.display = 'block';
        const cursor = document.getElementById('cursor');
        if (cursor) {
            cursor.style.display = 'none';
        }
        (document.querySelector('.nav-item.mobile.desktop') as HTMLElement).style.display = 'none';
        (document.querySelector('.nav-item.mobile.desktop.properties') as HTMLElement).style.display = 'block';
    }

    ngAfterViewInit(): void {
        window.addEventListener('message', (event) => {
            if (event.data.height) {
                $('iframe').height(event.data.height);
                if (event.data.resetScroll) {
                    window.scrollTo(0,0);
                }
            }

            const before = window.location.pathname.replace(/\/+$/, "");
            const after = `/properties${event.data.location}`.replace(/\/+$/, "");

            if (event.data.location !== undefined && event.data.location !== '/' && before !== after && after.split('//').length === 1) {
                // this.iframeLocation = this.sanitizer.bypassSecurityTrustResourceUrl('https://properties.steyncity.co.za/' + event.data.location);
                this.location.replaceState('properties' + event.data.location);
            }
        }, false);
    }

    scrollDown() {
        window.scrollTo({
            top: window.innerHeight + window.pageYOffset
            , behavior: 'smooth'
        });
    }

    getWindowTopY() {
        return window.pageYOffset;
    }

    ngOnDestroy() {
        if (document.querySelector('.nav-top-trans') && document.querySelector('.nav-top-trans').classList.contains('properties')) {
            document.querySelector('.nav-top-trans').classList.remove('properties');
            document.querySelector('.navbar-nav.mr-auto').classList.remove('properties');
            (document.querySelector('.navbar-item>div') as HTMLElement).style.display = 'block';
            (document.querySelector('.navbar-item img.properties') as HTMLElement).style.display = 'none';
            (document.querySelector('.nav-item.mobile.desktop') as HTMLElement).style.display = 'block';
            (document.querySelector('.nav-item.mobile.desktop.properties') as HTMLElement).style.display = 'none';
            const cursor = document.getElementById('cursor');
            if (cursor) {
                cursor.style.display = 'block';
                cursor.style.left = '-100vw';
                cursor.style.top = '-100vh';
            }
        }
    }
}
