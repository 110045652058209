import { AfterContentInit, Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UpdateService } from './core/update.service';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit {

    title = 'app';

    constructor(private readonly update: UpdateService, private deviceService: DeviceDetectorService) {
        this.update.checkForUpdate();
    }

    ngOnInit(): void {
        if (document.getElementsByTagName('main') && document.getElementsByTagName('main')[0]) {
            document.getElementsByTagName('main')[0].style.cursor = 'default';
        }

        if (document.getElementById('#bucket-decal-svg')) {
            document.getElementById('#bucket-decal-svg').style.display = window.location.pathname.indexOf('properties') ? 'none' : 'block';
        }
    }

    ngAfterContentInit(): void {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return;
        }

        const cursor = document.getElementById('cursor');
        const cursorCircle1 = document.getElementById('circle1');
        const cursorCircle2 = document.getElementById('circle2');

        $('*').mouseover((e: any) => {
            const currentCursor = $(e.target).css('cursor');
            if (currentCursor === 'pointer') {
                cursorCircle1.style.webkitAnimation = 'pulse 2s infinite';
                cursorCircle1.style.animation = 'pulse 2s infinite';
                cursorCircle2.style.webkitAnimation = 'pulse 4s infinite';
                cursorCircle2.style.animation = 'pulse 4s infinite';
            } else {
                cursorCircle1.style.webkitAnimation = 'none';
                cursorCircle1.style.animation = 'none';
                cursorCircle2.style.webkitAnimation = 'none';
                cursorCircle2.style.animation = 'none';
            }

        });

        window.onmousemove = (event: any) => {
            return setTimeout(() => {
                cursor.style.top = event.clientY + 'px';
                return cursor.style.left = event.clientX + 'px';
            }, 10);
        };

    }

    toggleSubscribe(open: boolean = true) {
        const subscribeDialogContainerStyle = document.getElementById('subscribe-dialog-container').style;
        if (open) {
            subscribeDialogContainerStyle.transform = 'translateX(0)';
            subscribeDialogContainerStyle.opacity = '1';
        } else {
            subscribeDialogContainerStyle.transform = 'translateX(100vw)';
            subscribeDialogContainerStyle.opacity = '0';
        }
    }

    scrollTop() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
}
